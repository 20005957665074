/**
 * Values for keys in this enum must match the feature flag name in
 * the database table docent.feature_flags
 */
export enum FeatureFlagKey {
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  ANALYTICS = 'ANALYTICS',
  DEVELOPER_MODE = 'DEVELOPER_MODE',
  BULK_UPLOAD = 'BULK_UPLOAD',
  LOANER_REPORT = 'LOANER_REPORT',
  MARKETING = 'MARKETING',
  CHANGE_USER_PERMISSION_TYPE = 'CHANGE_USER_PERMISSION_TYPE',
  EDIT_GOOGLE_MAPS_INFO = 'EDIT_GOOGLE_MAPS_INFO',
  LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
  DOWNLOAD_ASSETS = 'DOWNLOAD_ASSETS',
  CHANGE_USER_MFA_TYPE = 'CHANGE_USER_MFA_TYPE',
  EMAIL_MFA = 'EMAIL_MFA',
  USAGE_REPORTS = 'USAGE_REPORTS',
  PST_ART_GUIDE_LINKING = 'PST_ART_GUIDE_LINKING',
  MANAGE_MOBILE_WEB = 'MANAGE_MOBILE_WEB',
  SIGN_LANGUAGE_TOUR = 'SIGN_LANGUAGE_TOUR',
  UPDATE_IMAGE_CAPTION_ON_FIRST_ITEM_ASSOCIATION = 'UPDATE_IMAGE_CAPTION_ON_FIRST_ITEM_ASSOCIATION'
}

export type FeatureFlags = {
  [key in FeatureFlagKey]: boolean
}
